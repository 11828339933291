<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 14 12.25">
    <path
      :style="iconStyle"
      d="M.227-7.059a.657.657,0,0,0,0,.993L5.04-1.91a.657.657,0,0,0,1.085-.5v-2.4c4.241.061,6.068,1.114,4.829,5.079a.487.487,0,0,0,.768.509A5.59,5.59,0,0,0,14-3.436C14-7.6,10.517-8.479,6.125-8.529v-2.189a.657.657,0,0,0-1.085-.5Z"
      transform="translate(0 11.375)"
    />
  </svg>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '12.25',
    },
    width: {
      type: String,
      default: '14',
    },
    color: {
      type: String,
      default: 'menu',
    },
  },
  computed: {
    /**
     * Icon Style
     * @param {void}
     * @returns {string} - Color
     * @description Assigns the specific color to the icon
     */
    iconStyle: function () {
      return {
        fill: ['$' + this.color, `var(--${this.color})`],
      }
    },
  },
}
</script>
